import { TemplateProps } from '../../types/';

import './template.scss';


export const T3 = (props: TemplateProps) => {
  return (
    <div>
      Template 3
    </div>
  )
}
